import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: 150, height: 40, ...sx }}>
      <img src="/static/brand/logo_full.png" alt="" />
    </Box>
  );
}
