import { filter } from 'lodash'
import { createSlice } from '@reduxjs/toolkit'
// utils
import axios from '../../utils/axios'

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  requests: [],
  name: '',
  email: '',
  address: '',
  zip: '',
  city: '',
  country: '',
  verifyStatus: '',
  requestId: '',
  request: '',
  status: '',
}

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET REQUETS
    getRequestsSuccess(state, action) {
      state.isLoading = false
      state.requests = action.payload
    },

    // GET REQUET
    getRequestSuccess(state, action) {
      state.isLoading = false
      state.name = action.payload.name
      state.email = action.payload.email
      state.address = action.payload.address
      state.zip = action.payload.zip
      state.city = action.payload.city
      state.country = action.payload.country
      state.verifyStatus = action.payload.verifyStatus
      state.requestId = action.payload.requestId
      state.request = action.payload.request
      state.status = action.payload.status
    },

    // UPDATE STATUS
    updateStatusSuccess(state, action) {
      state.isLoading = false
      state.status = action.payload
    },

    // DELETE REQUET
    deleteRequest(state, action) {
      const deleteRequest = filter(
        state.requests,
        (request) => request.id !== action.payload
      )
      state.requests = deleteRequest
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const { deleteRequest } = slice.actions

// ----------------------------------------------------------------------

export function getrequests() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data } = await axios.get('/info')
      dispatch(slice.actions.getRequestsSuccess(data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------
export function getRequestByID(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data } = await axios.get(`/info/${id}`)
      dispatch(slice.actions.getRequestSuccess(data.data))
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}

// ----------------------------------------------------------------------
export function updateReqByID(id, steps) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading())
    try {
      const { data } = await axios.put(`/status/${id}`, steps)
      dispatch(slice.actions.updateStatusSuccess(data.data))
      console.log(data)
    } catch (error) {
      dispatch(slice.actions.hasError(error))
    }
  }
}
