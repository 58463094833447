import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  balances: {
    totalAmount: 0.0,
    depositAmount: 0.0
  }
};

const slice = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getBalancesSuccess(state, action) {
      state.isLoading = false;
      state.balances = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  deleteProduct,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity
} = slice.actions;

// ----------------------------------------------------------------------

export function getBalances() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = {
        data: {
          balances: {
            totalAmount: 187650.0,
            depositAmount: 25500.0
          }
        }
      };
      dispatch(slice.actions.getBalancesSuccess(response.data.balances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateBalance(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = {
        data: {
          balances: data
        }
      };
      dispatch(slice.actions.getBalancesSuccess(response.data.balances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
