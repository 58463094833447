// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components
import SvgIconStyle from '../../components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/navbar/${name}.svg`}
    sx={{ width: '100%', height: '100%' }}
  />
)

const ICONS = {
  dashboard: getIcon('ic_analytics'),
  accounts: getIcon('ic_banking'),
  wallets: getIcon('ic_ecommerce'),
  transactions: getIcon('ic_calendar'),
  send: getIcon('ic_send'),
  receive: getIcon('ic_receive'),
  new: getIcon('ic_add'),
}

const sidebarConfig = [
  {
    items: [
      {
        title: 'requests',
        path: PATH_DASHBOARD.requests.root,
        icon: ICONS.transactions,

        children: [
          {
            title: 'requests',
            path: PATH_DASHBOARD.requests.root,
          },
          {
            title: 'new',
            path: PATH_DASHBOARD.requests.new,
          },
        ],
      },
      {
        title: 'portfolio',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard,
      },
      {
        title: 'accounts',
        path: PATH_DASHBOARD.general.accounts.root,
        icon: ICONS.accounts,
      },
      {
        title: 'wallets',
        path: PATH_DASHBOARD.general.wallets.root,
        icon: ICONS.wallets,
      },
      // { title: 'send', path: PATH_DASHBOARD.general.send, icon: ICONS.send },
      // { title: 'receive', path: PATH_DASHBOARD.general.receive, icon: ICONS.receive }
    ],
  },
]

export default sidebarConfig
